<template>
  <b-modal id="bv-modal-example" v-model="modal" size="xl" hide-footer centered>
    <template #modal-title
      >{{ $t("clients.roulettes") }}: {{ client.name }}
    </template>
    <div>
      <div>
        <div>
          <b-table striped hover :items="roulettes" :fields="isAdmin">
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <i class="fa fa-fw fa-bars font-size-16" />
                </template>
                <div v-if="isItForAdmin()">
                  <b-dropdown-item @click="remove(data.item)">
                    <i class="uil-trash m-2 font-size-18" />
                    <span class="align-middle ml-5">{{
                      $t("actions.subItems.delete")
                    }}</span>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <EditRoulette :roulette="roulette" :client="item" ref="EditRoulette" />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditRoulette from "./editRoulette.vue";
import Swal from "sweetalert2";
export default {
  components: { EditRoulette },
  props: {
    item: {
      type: Array,
      default: () => {
        return [];
      },
    },
    roulettes: {
      type: Array,
    },
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userRole: {
      type: String,
    },
  },
  data() {
    return {
      modal: false,
      roulette: {},
    };
  },
  computed: {
    isAdmin() {
      const fields = [
        {
          key: "name",
          label: this.$t("filter.roulette"),
          class: "text-center",
        },
        {
          key: "pleno",
          label: this.$t("roulettes.form.pleno"),
          class: "text-center",
        },
        {
          key: "semipleno",
          label: this.$t("roulettes.form.semipleno"),
          class: "text-center",
        },
        {
          key: "calle",
          label: this.$t("roulettes.form.calle3"),
          class: "text-center",
        },
        {
          key: "cuadro",
          label: this.$t("roulettes.form.calle4"),
          class: "text-center",
        },
        {
          key: "linea",
          label: this.$t("roulettes.form.calle6"),
          class: "text-center",
        },
        {
          key: "columna",
          label: this.$t("roulettes.form.column"),
          class: "text-center",
        },
        {
          key: "docena",
          label: this.$t("roulettes.form.dozen"),
          class: "text-center",
        },
        {
          key: "chanceSimple",
          label: this.$t("roulettes.form.chanceSimple"),
          class: "text-center",
        },
        {
          key: "cubre",
          label: this.$t("roulettes.form.cubre"),
          class: "text-center",
        },
        {
          key: "roundDuration",
          label: this.$t("roulettes.form.roundDuration"),
          class: "text-center",
        },
      ];

      if (this.isItForAdmin()) {
        fields.push({
          key: "actions",
          sortable: false,
          label: this.$t("actions.text"),
          class: "align-middle  text-center",
        });
      }

      return fields;
    },
    ...mapGetters({
      isSuccess: "clients/isSuccess",
    }),
  },
  methods: {
    closeModa() {
      this.modal = false;
    },
    edit(item) {
      this.roulette = item;
      this.$refs.EditRoulette.modal = true;
    },
    async remove(item) {
      const continueAction = await this.sureToRemoveRoulette();
      if (!continueAction) return;

      try {
        await this.removeRoulette({ id: item.clientRoulette });
        if (!this.isSuccess)
          return Swal.fire("Error removing roulette", "", "info");

        Swal.fire({
          title: "Ruleta borrada",
          icon: "success",
        });

        this.closeModa();
      } catch (error) {
        console.log("ERROR REMOVING ROULETTE", error);
      }
    },
    async sureToRemoveRoulette() {
      try {
        const { isConfirmed } = await Swal.fire({
          title: "¿Estas seguro en eliminar la ruleta",
          icon: "question",
          showDenyButton: true,
        });

        if (!isConfirmed) return false;

        return true;
      } catch (err) {
        console.log("ERROR SURE REMOVE ROULETTE");
      }
    },
    isItForAdmin() {
      return ["ADMIN", "CLIENT"].includes(this.userRole);
    },
    ...mapActions({
      removeRoulette: "clients/removeRoulette",
    }),
  },
};
</script>

<style>
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 111%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.4rem;
  outline: 0;
}
</style>
