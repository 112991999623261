<template>
  <form @submit.prevent="save">
    <b-row>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>{{ $t("clients.form.name") }}</label>
          <vs-input v-model="typeform.name" :placeholder='$t("clients.form.name")' border class="shadow-lg" :class="{
            'is-invalid': typesubmit && $v.typeform.name,
          }" />
          <div v-if="typesubmit && $v.typeform.name" class="invalid-feedback">
            <span v-if="!$v.typeform.name.required">
              Este campo es requerido.
            </span>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>UUID</label>
          <vs-input v-model="typeform.uuid" placeholder='UUID' border class="shadow-lg" />
        </div>
      </b-col>
      <b-col sm="12" md="6" v-if="typeform._id">
        <div class="mb-3">
          <label>{{ $t('clients.form.token') }}</label>
          <vs-input v-model="typeform.token" placeholder="Token" border class="shadow-lg" :class="{
            'is-invalid': typesubmit && $v.typeform.token,
          }" />
          <div v-if="typesubmit && $v.typeform.token" class="invalid-feedback">
            Cancelar
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>{{ $t("clients.form.endpointAuth") }}</label>
          <div>
            <vs-input v-model="typeform.endpointAuth" :placeholder='$t("clients.form.endpointAuth")' border
              class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.endpointAuth,
              }" />
            <div v-if="typesubmit && $v.typeform.endpointAuth" class="invalid-feedback">
              <span v-if="!$v.typeform.endpointAuth.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>{{ $t('clients.form.endpointBet') }}</label>
          <div>
            <vs-input v-model="typeform.endpointBet" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.endpointBet,
            }" />
            <div v-if="typesubmit && $v.typeform.endpointBet" class="invalid-feedback">
              <span v-if="!$v.typeform.endpointBet.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>{{ $t('clients.form.endpointWin') }}</label>
          <div>
            <vs-input v-model="typeform.endpointWin" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.endpointWin,
            }" />
            <div v-if="typesubmit && $v.typeform.endpointWin" class="invalid-feedback">
              <span v-if="!$v.typeform.endpointWin.required">
                Este campo es requerido.
              </span>

            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>{{ $t('clients.form.endpointRollback') }}</label>
          <div>
            <vs-input v-model="typeform.endpointRollback" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.endpointRollback,
            }" />
            <div v-if="typesubmit && $v.typeform.endpointRollback" class="invalid-feedback">
              <span v-if="!$v.typeform.endpointRollback.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>{{ $t('clients.form.logoLobby') }}</label>
          <vs-input v-model="typeform.logo" border class="shadow-lg" />
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>{{ $t('clients.form.logoLoader') }}</label>
          <vs-input v-model="typeform.loaderLogo" border class="shadow-lg" />
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="my-4">
          <vs-checkbox id="checkbox-api-loaderLogo" name="checkbox-api-loaderLogo" v-model="typeform.useLogo">
            {{ $t('clients.form.useClientLogo') }}
          </vs-checkbox>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>URL GAMES</label>
          <div>
            <vs-input v-model="typeform.urlGames" class="shadow-lg" border placeholder="https://another-games.com" />
          </div>
        </div>
      </b-col>
    </b-row>

    <template v-if="!typeform._id">
      <hr />
      <h5>{{ $t('clients.initSession') }}:</h5>
      <br />
      <!-- Para registrar user de client -->

      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('initSession.role') }}</label>
            <label class="d-inline-flex align-items-center w-100">
              <select v-model="typeform.role" name="role" class="form-select form-control border-0 shadow-lg"
                :required="typeform._id ? false : true">
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option v-for="(item, index) in roles" :key="index" :value="item">
                  {{ item.name }}
                </option>
              </select>
            </label>
            <div v-if="typesubmit && $v.typeform.name" class="invalid-feedback">
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('initSession.email') }}</label>
            <vs-input v-model="typeform.email" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.email,
            }" :required="typeform._id ? false : true" />
            <div v-if="typesubmit && $v.typeform.email" class="invalid-feedback">
              <span v-if="!$v.typeform.email.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('initSession.password') }}</label>
            <vs-input v-model="typeform.password" type="password" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.name,
            }" :required="typeform._id ? false : true" />
            <div v-if="typesubmit && $v.typeform.password" class="invalid-feedback">
              <span v-if="!$v.typeform.password.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('initSession.confirmPassword') }}</label>
            <vs-input v-model="typeform.password2" type="password" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.password2,
            }" :required="typeform._id ? false : true" />
            <div v-if="typesubmit && $v.typeform.password2" class="invalid-feedback">
              <span v-if="!$v.typeform.password2.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>

    <div class="mb-3 mb-0">
      <div class="d-flex">
        <vs-button :loading="loading">{{ $t('form.save') }}</vs-button>
        <vs-button type="button" @click="$emit('closeModa')" success>
          {{ $t('form.abort') }}
        </vs-button>
      </div>
    </div>
  </form>
</template>

<script>
import { required, /* url */ } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      loading: false,
      roles: [],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      endpointAuth: {
        required,

      },
      endpointBet: {
        required,
      },
      endpointWin: {
        required,

      },
      endpointRollback: {
        required,

      },
    },
  },
  computed: {
    ...mapGetters({
      mapRoles: "roles/getRoles",
      isSuccess: "clients/isSuccess",
      message: "clients/getMessage",
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors'
    })
  },
  methods: {
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: "auto",
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`
          this.openWinNotification(null, 'warning', 'ERROR', message)
        })
      }
    },
    userAction() {
      // let target = e.target.querySelector("[data-action]");
      // let action = target.getAttribute("data-action");
      let route = this.$route.name;
      return {
        route,
      };
    },
    async getRoles() {
      await this.fetchRoles()
      this.roles = this.mapRoles;
    },
    save() {
      this.typesubmit = true;
      this.loading = true
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("typeform", this.typeform);
        this.loading = false
        return console.log("error", this.$v);
      }
      if (this.typeform._id) return this.updateClient();

      this.createClient();
    },
    async updateClient() {
      try {
        await this.update({ doc: this.typeform })

        if (!this.isSuccess) {
          this.loading = false
          return this.handleAlertSwal("error", "Error guardando el cliente")
        }

        this.loading = false
        this.checkErrors()
        this.handleAlertSwal("success", "Usuario guardado con exito")
        this.$emit("closeModa");
        this.$emit("getData");
      } catch (error) {
        console.log('ERROR UPDATE CLIENT', error);
      }
    },
    async createClient() {
      try {

        await this.create({ doc: this.typeform })

        if (!this.isSuccess) {
          this.loading = false
          return this.handleAlertSwal("error", this.message)
        }

        this.loading = false
        this.checkErrors()
        this.handleAlertSwal("success", this.message)
        this.$emit("closeModa");
        this.$emit("getData");
      } catch (error) {
        console.log('ERROR CREATE CLIENT', error);
      }

    },
    handleAlertSwal(icon, title) {
      Swal.fire({
        position: "center",
        icon,
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    ...mapActions({
      fetchRoles: "roles/fetchRoles",
      update: "clients/updateClient",
      create: "clients/createClient",
    })
  },
  mounted() {
    this.getRoles();
  },
};
</script>
