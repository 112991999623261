import i18n from '../i18n'

export const clientFields = [
        {
          key: "name",
          sortable: true,
          label: i18n.t("clients.name").toUpperCase(),
        },
        {
          key: "logo",
          sortable: false,
          label: i18n.t("clients.logo").toUpperCase(),
          class: "text-center",
        },
        {
          key: "urls",
          sortable: false,
          label: i18n.t("clients.URLs").toUpperCase(),
          class: "text-center align-middle",
        },
        {
          key: "games",
          sortable: false,
          label: i18n.t("sidebar.games.text").toUpperCase(),
          class: "text-center align-middle",
        },
        {
          key: "available",
          sortable: false,
          label: i18n.t("actions.subItems.status").toUpperCase(),
          class: "text-center align-middle",
          formatter: (item) => {
            return item
              ? "ACTIVO" : "BLOQUEADO"
          }
        },
        {
          key: "actions",
          sortable: false,
          label: i18n.t("actions.text").toUpperCase(),
          class: "align-middle text-start",
        },
]
