<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title> Agregar Rueda </template>
    <div>
      <form @submit.prevent="save()">
        <div class="mb-3 mx-auto">
          <multiselect
            v-model="value"
            label="name"
            track-by="name"
            :options="wheels"
            @tag="addTag">
          </multiselect>
        </div>

        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="closeModa()" type="button" success>
            Cancelar
          </vs-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
    wheels: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { Multiselect },
  data() {
    return {
      typesubmit: false,
      modal: false,
      value: null,
    };
  },
  computed: {
    ...mapGetters({
      success: "clients/isSuccess",
    }),
  },
  methods: {
    addTag(newTag) {
      const tag = newTag;

      this.value.push(tag);
    },
    async save() {
      this.typesubmit = true;

      await this.addWheel({
        uuid: this.client.uuid,
        gameUuid: this.value.uuid,
      });

      if (!this.success) return Swal.fire("Error adding wheel", "", "info");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Rueda agregada con exito",
        showConfirmButton: false,
        timer: 1500,
      });
      this.closeModa();
    },
    closeModa() {
      this.modal = false;
      this.value = null;
      this.$emit("closeGameMenu");
    },
    ...mapActions({
      addWheel: "clients/addWheel",
    }),
  },
};
</script>
